<template>
  <div class="documents">
    <ul class="documents__list">
      <li
        v-for="doc in docs"
        :key="doc.name"
        class="documents__item"
      >
        <x-icon
          name="list"
          class="documents__item-icon"
        />

        <x-link
          primary
          :href="doc.link"
        >
          {{ doc.name }}
        </x-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { STORAGE_CREDENTIALS_AGREEMENT, OFFER, PRIVACY, SUBSCRIPTION_CONSENT, REMUNERATION_AGREEMENT } from '@/constants/links'

export default {
  data () {
    return {
      docs: [{
        name: 'Оферта',
        link: OFFER
      }, {
        name: 'Политика конфиденциальности',
        link: PRIVACY
      },
      {
        name: 'Сохранение учетных данных для будущих транзакций',
        link: SUBSCRIPTION_CONSENT
      },
      {
        name: 'Соглашение с владельцем карты',
        link: STORAGE_CREDENTIALS_AGREEMENT
      },
      {
        name: 'Соглашение о рекуррентных платежах',
        link: REMUNERATION_AGREEMENT
      }]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .documents
    &__list
      list-style none

    &__item
      display flex
      align-items center
      margin-bottom 12px

    &__item-icon
      margin-right 10px
      color $colors.gray-dark
</style>
