import { render, staticRenderFns } from "./Documents.vue?vue&type=template&id=7072200f&scoped=true&"
import script from "./Documents.vue?vue&type=script&lang=js&"
export * from "./Documents.vue?vue&type=script&lang=js&"
import style0 from "./Documents.vue?vue&type=style&index=0&id=7072200f&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7072200f",
  null
  
)

export default component.exports